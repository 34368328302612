<template>
  <WeContainer card="">
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-cube"></i>
        <span>Ürün İptal & İade Raporları</span>
      </h5>
    </WeCard>
    <WeTableSearch
      v-on:search="onSearch"
      v-on:clear="onFilterClear"
      v-bind:show-icon="false"
      header-class="bg-white text-dark"
      header-text-class="text-dark h6"
      v-bind:show-on-load="true"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Tarih"
                placeholder="Tarih"
                v-bind:date-range="true"
                v-model="filter.dates"
                selected-shortcut="thisMonth"
              />
            </div>
            <!-- Created At -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Category -->
            <div class="d-block mb-3">
              <WeRowInput
                form="searchSelect"
                size="sm"
                name="category"
                label="Kategori"
                placeholder="Kategori"
                v-bind:clearable="true"
                v-model="filter.category"
              />
            </div>
            <!-- Category -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeCard>
      <div class="position-relative" style="min-height: 250px" v-if="loading">
        <WeLoading />
      </div>
      <div v-else v-bind:id="printConfig.id">
        <span v-print="printConfig" ref="printButton"> </span>
        <div class="text-center print">
          <h5 class="font-weight-bold">{{ getFileName }}</h5>
        </div>
        <WeTable
          v-bind:index="false"
          v-bind:columns="getColumns"
          v-bind:data="list"
          v-bind:actions="actions"
          v-bind:exportables="exportables"
          v-on:on-export="onExport"
          v-on:on-action="onAction"
          v-bind:paginatable="paginatable"
          v-bind:pageDetails="pageDetails"
          v-bind:limitable="limitable"
          v-bind:searchable="searchable"
        />
      </div>
    </WeCard>

    <ProductReturnDetail
      v-if="showModal && productDetail"
      v-bind:detail="productDetail"
      v-bind:loading="modalLoading"
      v-on:close="onCloseDetail"
    />
  </WeContainer>
</template>
<script>
import { mapState, mapActions } from "vuex";
const ProductReturnDetail = () =>
  import("./views/ProductReturnDetail/Index.vue");

export default {
  name: "Returns",
  components: {
    ProductReturnDetail,
  },
  data() {
    return {
      loading: true,
      modalLoading: true,
      showModal: false,
      paginatable: true,
      pageDetails: true,
      limitable: true,
      searchable: true,
      filter: {
        dates: {
          start: null,
          end: null,
        },
        category: null,
      },
      list: [],
      columns: [
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "ecommerce_quantity", th: "E-Ticaret Miktar", type: "integer" },
        { name: "ecommerce_price", th: "E-Ticaret Toplam", type: "currency" },
        { name: "total_quantity", th: "Toplam Miktar", type: "integer" },
        { name: "total_price", th: "Toplam Tutar", type: "currency" },
      ],
      actions: [
        {
          icon: "fas fa-list",
          class: "btn-outline-indigo",
          action: "detail",
          tooltip: "Sipariş Listesi",
        },
      ],
      exportables: ["excel", "pdf", "print"],
      printConfig: {
        id: "ProductReturnReport",
        popTitle: "Ürün İptal & İade Raporları",
      },
      productDetail: null,
    };
  },
  methods: {
    ...mapActions("reports", ["makeSearch", "show"]),
    ...mapActions("tableExport", ["exportData"]),
    getFilter(copyDates = true) {
      let copy = helper.clone(this.filter);
      copy.dates = [];

      if (
        copyDates &&
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        Object.entries(this.filter.dates).forEach(([key, value]) => {
          if (value) {
            copy.dates.push(value);
          }
        });
      } else {
        delete copy.dates;
      }

      return copy;
    },
    onSearch() {
      this.loading = true;
      this.makeSearch({
        routeKey: "productReturn",
        filter: this.getFilter(),
        onSuccess: (result) => {
          this.list = result.data.items;
          this.tableTotalPrice = localization.getMoneyFormat(
            null,
            result.data.table_total
          );
          this.loading = false;
        },
      });
    },
    onExport(data) {
      if (this.list && this.list.length) {
        if (data.name !== "print") {
          let fileName = "Ürün İptal & İade Raporları";

          if (
            this.filter.dates &&
            this.filter.dates.start &&
            this.filter.dates.end
          ) {
            let startDate = new Date(
              this.filter.dates.start
            ).toLocaleDateString("tr-TR");
            let endDate = new Date(this.filter.dates.end).toLocaleDateString(
              "tr-TR"
            );

            fileName = startDate + " - " + endDate + " - " + fileName;
          }

          this.exportData({
            route: "report/products/returns/export",
            exportable: data,
            fileName: fileName,
            filter: {
              ...this.getFilter(),
              type: data.name,
            },
          });
        } else {
          this.setPrintableSettings(true);
          this.$refs.printButton.click();

          setTimeout(() => {
            this.setPrintableSettings(false);
          }, 100);
        }
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veya Yazdırılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    setPrintableSettings(print = false) {
      let marketplaceQuantity = print ? "PY Miktar" : "Pazaryeri Miktar";
      let marketplaceTotalAmount = print ? "PY Toplam" : "Pazaryeri Toplam";
      let ecommerceQuantity = print ? "ET Miktar" : "E-Ticaret Miktar";
      let ecommerceTotalAmount = print ? "ET Toplam" : "E-Ticaret Toplam";

      this.getColumns.map((item) => {
        switch (item.name) {
          case "marketplace_quantity":
            item.th = marketplaceQuantity;
            break;

          case "marketplace_price":
            item.th = marketplaceTotalAmount;
            break;

          case "ecommerce_quantity":
            item.th = ecommerceQuantity;
            break;

          case "ecommerce_price":
            item.th = ecommerceTotalAmount;
            break;
        }

        return item;
      });

      this.paginatable = !print;
      this.pageDetails = !print;
      this.limitable = !print;
      this.searchable = !print;

      this.list.sort();
    },
    onAction(data) {
      if (data.key == "detail") {
        this.showDetail(data.row);
      }
    },
    onFilterClear() {
      this.filter = {
        dates: {
          start: null,
          end: null,
        },
        category: null,
      };
      this.onSearch();
    },
    showDetail(row) {
      this.productDetail = {};
      this.productDetail.id = row.id;
      this.productDetail.title = row.sku_no;
      this.showModal = true;
      this.modalLoading = true;

      this.show({
        routeKey: "productReturnOrder",
        id: row.id,
        filter: this.getFilter(),
        onSuccess: (result) => {
          this.productDetail.orders = result.data.items;
          this.productDetail.filter = this.getFilter();
        },
        onFinish: () => {
          this.modalLoading = false;
        },
      });
    },
    onCloseDetail() {
      this.showModal = false;
      this.productDetail = null;
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    getFileName() {
      const defaultName = "Ürün İptal & İade Raporları";
      let result = "";

      if (
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        let startDate = this.filter.dates.start;
        let endDate = this.filter.dates.end;

        startDate = new Date(startDate).toLocaleDateString("tr-TR");
        endDate = new Date(endDate).toLocaleDateString("tr-TR");

        result = `${startDate} - ${endDate} ${defaultName}`;
      } else {
        result = defaultName;
      }

      return result;
    },
    getColumns() {
      let columns = this.columns;
      let customColumns = [];

      if (this.marketplaceConfig) {
        customColumns = [
          {
            name: "marketplace_quantity",
            th: "Pazaryeri Miktar",
            type: "integer",
          },
          {
            name: "marketplace_price",
            th: "Pazaryeri Toplam",
            type: "currency",
          },
        ];
      }
      if (customColumns.length) {
        for (let i = customColumns.length - 1; i >= 0; i--) {
          const custom = customColumns[i];
          columns.splice(2, 0, custom);
        }
      }
      return columns;
    },
    marketplaceConfig() {
      return (
        this.config["site.marketplace_integration"] == "1" ||
        this.config["site.marketplace_integration"] == 1
      );
    },
  },
  mounted() {
    this.onSearch();
  },
};
</script>